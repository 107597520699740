<template>
    <div class="video">
        <van-nav-bar title="视频查看" left-text="返回" left-arrow @click-left="onClickLeft"/>
        <div class="content">
            <video controls ref="video" muted="muted"></video>
        </div>
        <div class="handle-area">
            <div class="btn" @click="streamInfo"><van-icon name="play-circle-o" />开始播放</div>
            <div class="btn" @click="stopCom"><van-icon name="stop-circle-o" />停止播放</div>
        </div>
        <div class="handle-area">
            <div class="btn-cam" @click="camCapture"><van-icon name="photo-o" />图片抓拍</div>
        </div>
    </div>
</template>

<script>
// import flvjs from "flv.js";
let Hls = require('hls.js');


const mqtt = require('mqtt');
import MQTT_OPTIONS from '@/config/mqtt.js'

export default {
    
    data(){
        return{
            cameraCode:'',
            projectCode:null,
            flvPlayer:null,
            client:null,
            hls:null
        }
    },
    methods:{
        onClickLeft(){
            this.$router.go(-1);//返回上一层
        },
        getCameraInfo(){
            this.$api.NEWBRIDGE.cameraInfo({
                projectCode:this.projectCode
                }).then((data)=>{
                   if(Array.isArray(data)){
                        let dev = data[0];
                        this.cameraCode = dev && dev.devCode || '';
                        if(this.cameraCode.length > 0){
                            this.streamInfo();
                        }else{
                            console.log("相机编号为空！");
                        }
                   }
                })
        },
        streamInfo(){
            //发送关闭指令
            this.$api.NEWBRIDGE.streamInfo({
                devCode:this.cameraCode,
                }).then((data)=>{
                    if(data.flag){
                        console.log("控制播放！");
                        this.getHlsStream(data.flv)
                    }else{
                        console.log("控制推流！");
                        this.startCom();
                    }
                })
            
        },
        camCapture(){ //抓拍
            //"action": 0,
            //"channel": 1,
            //"count": 1,
            //"codec": 0,
            //"wdr": 0,
            //"msg_type": 4,
            //"dev_code": "356802050227908"

            //发送关闭指令
            this.$api.NEWBRIDGE.videoCommand({
                dev_code:this.cameraCode,
                msg_type:4,
                action:1,
                channel:14,
                count:1,
                codec:0,
                wdr:0
                }).then((data)=>{
                    this.$toast(data.msg);
                    //resolve();
                })
            
        },
        stopCom(){
            //this.$toast('实时视频待接入，敬请期待')
            // "dev_code": "356802050227908",
            // "msg_type": 14
            //发送关闭指令
            this.$api.NEWBRIDGE.videoCommand({
                dev_code:this.cameraCode,
                msg_type:14
                }).then((data)=>{
                    //this.$toast(data.msg);
                    console.log(data,'发送关闭指令');
                    // resolve();
                })
            
        },
        startCom(){
            //"action": 0,
            //"channel": 1,
            //"codec": 1,
            //"wdr": 0,
            //"count": 60,
            //"dev_code": "356802050227460",
            //"msg_type": 12

            //发送开启指令
            this.$api.NEWBRIDGE.videoCommand({
                action:0,
                channel:1,
                wdr:1,
                count:180,
                dev_code:this.cameraCode,
                msg_type:12,
                codec:1
                }).then((data)=>{
                   //this.$toast(data.msg);
                    console.log(data,'发送开启指令');
                   // resolve();
                })
        },
        mqttMsg(){
            this.client  = mqtt.connect('ws://47.101.147.59:8083/mqtt',MQTT_OPTIONS);
            this.client.on('connect', (e) => {
                console.log(e, "MQTT连接成功！！！");
                const topicVideo = '/sync/mediaserver/camera/'+this.cameraCode+'/changed/upload'; // 视频Topic
                this.client.subscribe(topicVideo, {}, (error) => {  // qos 为通道
                    if (!error) {
                        console.log('视频消息订阅成功',topicVideo)
                    } else {
                        // console.log('视频消息订阅失败')
                    }
                })
            })
            // 接收消息处理
            this.client.on('message', (topic, message) => {
                const msg = JSON.parse(message.toString());
                this.handleVideoMsg(msg)
            })
            // 断开发起重连
            this.client.on('reconnect', (error) => {
                console.log('正在重连:', error)
            })
            // 链接异常处理
            this.client.on('error', (error) => {
                console.log('连接失败:', error)
            })
        },
        handleVideoMsg(msg){
            let regist = msg.regist
            console.log('regist:', regist)
            if(regist){
                this.getHlsStream(msg.flv)
            }else{
                this.videoHlsDestroyed()
            }
        },
        // 获取hls视频流
        getHlsStream(source) {
            // 处理流地址 flv转m3u8
            const handledSource = source.substring(0,source.indexOf("?")).replace('.flv','/hls.m3u8');
            if (Hls.isSupported()) {
                this.hls = new Hls();
                this.hls.loadSource(handledSource);
                this.hls.attachMedia(this.$refs.video);

                this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    console.log("加载成功");
                    this.$refs.video.play();
                });
            }else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
                // 以下逻辑兼容IOS浏览器内核
                this.$refs.video.src = handledSource;
                this.$refs.video.addEventListener('loadedmetadata',function() {
                    this.$refs.video.play();
                });
            }
        },
        videoHlsDestroyed(){
            if (this.hls) {
                this.$refs.video.pause();
                this.hls.destroy();
                this.hls = null;
            }
        },
        //获取flv流
        // getFlvStream(source){
        //     if (flvjs.isSupported()) {
        //         this.flvPlayer = flvjs.createPlayer({
        //             type: "flv",
        //             isLive: true,
        //             hasAudio: false,
        //             url: source,
        //         });
        //         this.flvPlayer.attachMediaElement(this.$refs.video);
        //         this.flvPlayer.load(); //加载
        //         this.flvPlayer.play(); //播放
        //         // 断流重连
        //         this.flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail) => {
        //             console.log("网络波动,正在尝试连接中...")
        //         })
        //     }else{
        //     }
        // },
        // // 销毁flv流
        // videoFlvDestroyed(){
        //     if (this.flvPlayer != null) {
        //         this.flvPlayer.pause();
        //         this.flvPlayer.unload();
        //         this.flvPlayer.detachMediaElement();
        //         this.flvPlayer.destroy();
        //         this.flvPlayer = null;
        //     }
        // },
        
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');

        setTimeout(()=>{
            this.getCameraInfo();
            
        },1)
        this.mqttMsg();
    },
    beforeDestroy(){
        this.client ? this.client.end() : void 0;
        this.videoHlsDestroyed();
    }
}
</script>

<style lang="scss" scoped>
.video{
    .content{
        video{
            width: 100%;
            height: 600px;
        }
    }
    //4aba9c
    //9be2cf
    .handle-area{
        display: flex;
        justify-content: space-around;
        margin: 10px 0;
        .btn{
            background-color: #4aba9c;
            color: #fff;
            border-radius: 10px;
            padding: 12px 30px;
            width: 40%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            &:active{
                opacity: 0.7;
            }
            .van-icon{
                padding-right: 5px;
                font-size: 20px;
            }
        }
        .btn-cam{
            background-color: #4aba9c;
            color: #fff;
            border-radius: 10px;
            padding: 12px 30px;
            width: 90%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            &:active{
                opacity: 0.7;
            }
            .van-icon{
                padding-right: 5px;
                font-size: 20px;
            }
        }
    
    }
}
</style>